import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { IonicModule, NavController, PopoverController } from '@ionic/angular';

import { Project } from 'src/models/projects';
import { AuthService } from 'src/services/auth.service';
import { ProjectsService } from 'src/services/projects.service';

@Component({
  selector: 'app-project-selector',
  standalone: true,
  imports: [CommonModule, IonicModule],
  template: `
    <ion-list class="project-list">
      <!-- All Projects Option -->
      <ion-item
        button="true"
        detail="false"
        (click)="selectProject(null)"
        [class.selected]="!selectedProjectId"
        lines="none"
        class="ion-item-rounded"
      >
        <ion-label>
          <h2>{{ tenantName }} (All Projects)</h2>
        </ion-label>
        <ion-icon *ngIf="!selectedProjectId" name="checkmark" slot="end" color="primary"></ion-icon>
      </ion-item>

      <ion-item-divider>
        <ion-label>Projects</ion-label>
      </ion-item-divider>

      <!-- Project List -->
      <ion-item
        button="true"
        detail="false"
        *ngFor="let project of projects"
        (click)="selectProject(project)"
        [class.selected]="project.id === selectedProjectId"
        lines="none"
        class="ion-item-rounded"
      >
        <img
          *ngIf="project.supportLogo"
          [src]="project.supportLogo"
          style="width: 16px; height: 16px; border-radius: 4px; margin-right: 8px"
        />
        <ion-icon name="folder-open-outline" slot="start" *ngIf="!project.supportLogo"></ion-icon>
        <ion-label>
          <h2>{{ project.name }}</h2>
        </ion-label>
        <ion-icon *ngIf="project.id === selectedProjectId" name="checkmark" slot="end" color="primary"></ion-icon>
      </ion-item>

      <ion-item-divider *ngIf="authService.isTenantAdmin"></ion-item-divider>

      <!-- Manage Projects Option -->
      <ion-item
        button
        detail="false"
        (click)="manageProjects()"
        lines="none"
        class="manage-projects"
        *ngIf="authService.isTenantAdmin"
        style="--padding-start: 12px"
      >
        <ion-icon name="settings-outline" slot="start"></ion-icon>
        <ion-label>
          <h2>Manage Projects</h2>
        </ion-label>
      </ion-item>
    </ion-list>
  `,
  styles: [
    `
      .project-list {
        min-width: 280px;
        max-width: 320px;
        margin: 0;
        padding: 8px 0 0 0;
        background: var(--ion-background-color);
      }

      ion-item {
        &.selected {
          --background: var(--ion-color-step-50);
          font-weight: 500;
        }

        ion-label h2 {
          font-size: 0.75rem;
          margin: 0;
          font-weight: inherit;
        }
      }

      ion-item-divider {
        font-size: 0.7rem;
        color: var(--ion-color-medium);

        &:empty {
          min-height: 8px;
        }
      }

      ion-icon {
        font-size: 18px;
        margin: 0 6px 0 0;
      }

      .manage-projects {
        color: var(--ion-color-medium);
      }
    `,
  ],
})
export class ProjectSelectorComponent implements OnInit {
  projects: Project[] = [];
  selectedProjectId: string | null = null;
  tenantName: string;
  tenantIcon?: string;

  constructor(
    public authService: AuthService,
    private popoverCtrl: PopoverController,
    private projectsService: ProjectsService,
    private navCtrl: NavController,
  ) {}

  async ngOnInit() {
    this.selectedProjectId = this.authService.selectedProject?.id;
    this.tenantName = this.authService.tenant.name;
    this.tenantIcon = this.authService.tenant.supportLogo;

    // Get the list of projects from the database
    const response = await this.projectsService.list({
      limit: 100,
      order: ['name'],
      descending: false,
    });

    this.projects = response.data.map((p) => p as Project);
  }

  selectProject(project: Project | null) {
    this.authService.storeSelectedProject(project);
    window.location.reload();
  }

  manageProjects() {
    this.navCtrl.navigateRoot('/dashboard/settings/project-list');
    this.popoverCtrl.dismiss();
  }
}
